"use client";
import type { ReactNode } from "react";
import { ChevronRight } from "@mui/icons-material";
import { useState } from "react";

export const FaqCard = ({
  header,
  children,
}: {
  header: string;
  children: ReactNode;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="p-4 rounded bg-white w-full space-y-4">
      <div
        className="flex justify-between"
        onClick={() => setIsExpanded(!isExpanded)}
        role="button"
      >
        <span>{header}</span>
        <ChevronRight
          style={{ transform: isExpanded ? "rotate(-90deg)" : "rotate(90deg)" }}
        />
      </div>
      {isExpanded && <div className="text-gray-600">{children}</div>}
    </div>
  );
};
