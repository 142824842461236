"use client";
import type { ReactNode } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

export const SlideUpOnScroll = ({ children }: { children: ReactNode }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const control = useAnimation();

  useEffect(() => {
    if (isInView) {
      control.start("visible");
    } else {
      //control.start("hidden");
    }
  }, [control, isInView]);

  const slideVariant = {
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
    hidden: { opacity: 0, y: 100 },
  };

  return (
    <div ref={ref}>
      <motion.div initial="hidden" variants={slideVariant} animate={control}>
        {children}
      </motion.div>
    </div>
  );
};
export const FadeIn = ({ children }: { children: ReactNode }) => {
  return (
    <div>
      <motion.div
        initial={{ opacity: 0.1 }}
        animate={{ opacity: 1, transition: { duration: 0.8 } }}
      >
        {children}
      </motion.div>
    </div>
  );
};
