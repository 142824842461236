"use client";

import { isMobileScreen } from "@/utils/device";
import { Dialog, TextField } from "@mui/material";
import Link from "next/link";
import { $fetch } from "ofetch";
import React, { ReactNode, useState } from "react";
import { Controller, useForm } from "react-hook-form";

export const SignUpCallout = ({
  children,
  linkText,
}: {
  children: React.ReactNode;
  linkText: string;
}) => (
  <section className="text-center max-w-[800px] lg:mx-auto space-y-8 mx-2">
    <p className="text-2xl mb-2 leading-normal">{children}</p>
    <div className="flex space-x-4 items-center justify-center">
      <Link href="/app/auth/sign-up">
        <span className="btn-primary">{linkText}</span>
      </Link>
      <div className="font-bold opacity-90">
        <EmailModal />
      </div>
    </div>
    <p>
      Or contact us on{" "}
      <a href="mailto:help@yachtsector.com" className="text-b2">
        help@yachtsector.com
      </a>{" "}
      if you have questions
    </p>
  </section>
);

export const EmailModal = ({ children }: { children?: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [submitState, setSubmitState] = useState("INITIAL");
  const [submittedEmail, setSubmittedEmail] = useState("");

  const handleClickOpen = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    if (open) {
      setOpen(false);
    }
    setTimeout(() => {
      setSubmitState("INITIAL");
      setSubmittedEmail("");
    }, 200);
  };

  const isMobile = isMobileScreen();

  const handleSubmitForm = async (formData: EmailFormData) => {
    console.log("formData");
    console.log(formData);
    setSubmitState("LOADING");
    try {
      const submitResponse = await $fetch(
        `${process.env.NEXT_PUBLIC_HASURA_URL}/api/rest/insert_email_submission`,
        {
          method: "POST",
          body: { email: formData.email },
        }
      );
      setSubmittedEmail(formData.email);
      setSubmitState("SUCCESS");
    } catch (e) {
      console.error(e);
      setSubmitState("ERROR");
    }
  };

  return (
    <div>
      <button onClick={handleClickOpen}>
        {children || (
          <span className="inline-block px-4 py-2 font-sans drop-shadow-md">
            Get in touch
          </span>
        )}
      </button>
      <Dialog
        onClose={handleClose}
        open={open}
        maxWidth={isMobile ? false : "sm"}
        fullScreen={isMobile}
        scroll="body"
      >
        <div className="px-4 py-8 lg:p-8 sm:w-[512px]">
          {(submitState === "SUCCESS" && (
            <div className="text-black flex flex-col items-center space-y-8">
              <div className="space-y-4">
                <div className="text-2xl text-center">Thank you</div>
                {submittedEmail && (
                  <div>We will contact you on {submittedEmail} soon</div>
                )}
              </div>
              <div>
                <button className="btn-black" onClick={handleClose}>
                  Close
                </button>
              </div>
            </div>
          )) || (
            <div className="space-y-4">
              <div className="space-y-2 text-black">
                <div className="font-bold">
                  Thanks for your interest in Yacht Sector
                </div>
                <div>
                  Please leave your email below, we’ll get back to you soon.
                </div>
              </div>
              <EmailForm handleSubmitForm={handleSubmitForm} />
              <div className="flex space-x-4 items-center">
                <button
                  className="btn-black btn-loading"
                  type="submit"
                  form="landing-email-form"
                  data-loading={submitState === "LOADING"}
                  disabled={submitState === "LOADING"}
                >
                  Send
                </button>
                <button className="px-4 py-2 font-bold" onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};

type EmailFormData = {
  email: string;
};

const EmailForm = ({
  handleSubmitForm,
}: {
  handleSubmitForm: (formData: EmailFormData) => void;
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
  } = useForm<EmailFormData>({ defaultValues: { email: "" } });

  return (
    <form id="landing-email-form" onSubmit={handleSubmit(handleSubmitForm)}>
      <Controller
        name="email"
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            value={value}
            onChange={onChange}
            label="Your email"
            inputProps={{ type: "email" }}
            required
            error={!!errors.email}
          />
        )}
      />
    </form>
  );
};
