export const isMobileScreen = () => {
  if (typeof window === "undefined") {
    return false;
  }
  return window.innerWidth <= 768;
};
export const supportsPdfPreview = () => {
  if (typeof window === "undefined") {
    return true;
  }
  const isIos =
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document);

  if (isIos) {
    return false;
  } else {
    return !isMobileScreen();
  }
};
