"use client";
import { useEffect, useRef } from "react";

export const useAutoplayVideo = ({ videoId }: { videoId: string }) => {
  const screenWidth = useRef(0);
  const screenHeight = useRef(0);

  useEffect(() => {
    screenWidth.current = Math.max(window.innerWidth, 1200);
    screenHeight.current = window.innerHeight;
    // Video might not autoplay on Safari.
    // We disable autoplay and instead play it manually.
    // This is in order to not display a big play icon on the video.
    const videoElement = document.getElementById(videoId) as HTMLVideoElement;
    try {
      videoElement?.play();
    } catch {}
  }, []);
};

export const AutoplayVideo = ({ videoId }: { videoId: string }) => {
  useAutoplayVideo({ videoId });
  return null;
};
